// Generated by Framer (0961f6c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["d_2CcWnJV"];

const serializationHash = "framer-dOuoj"

const variantClassNames = {d_2CcWnJV: "framer-v-84zr2k"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, title, width, ...props}) => { return {...props, DApvjrdfG: title ?? props.DApvjrdfG ?? "About "} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, DApvjrdfG, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "d_2CcWnJV", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={{webPageId: "ROYpETYmT"}}><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-84zr2k", className, classNames)} framer-wg7km7`} data-framer-name={"Closed"} layoutDependency={layoutDependency} layoutId={"d_2CcWnJV"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(0, 0, 0)", ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7Um9ib3RvLTUwMA==", "--framer-font-family": "\"Roboto\", \"Roboto Placeholder\", sans-serif", "--framer-font-size": "15px", "--framer-font-weight": "500", "--framer-letter-spacing": "-0.01em", "--framer-line-height": "2em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>About </motion.p></React.Fragment>} className={"framer-1c14pgu"} fonts={["GF;Roboto-500"]} layoutDependency={layoutDependency} layoutId={"NFkD9U6yv"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={DApvjrdfG} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-dOuoj.framer-wg7km7, .framer-dOuoj .framer-wg7km7 { display: block; }", ".framer-dOuoj.framer-84zr2k { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 5px; height: min-content; justify-content: center; overflow: hidden; padding: 5px 0px 5px 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-dOuoj .framer-1c14pgu { -webkit-user-select: none; flex: none; height: auto; position: relative; user-select: none; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-dOuoj.framer-84zr2k { gap: 0px; } .framer-dOuoj.framer-84zr2k > * { margin: 0px; margin-left: calc(5px / 2); margin-right: calc(5px / 2); } .framer-dOuoj.framer-84zr2k > :first-child { margin-left: 0px; } .framer-dOuoj.framer-84zr2k > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 43
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"DApvjrdfG":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameroDm28HZtn: React.ComponentType<Props> = withCSS(Component, css, "framer-dOuoj") as typeof Component;
export default FrameroDm28HZtn;

FrameroDm28HZtn.displayName = "Trigger Copy 9";

FrameroDm28HZtn.defaultProps = {height: 40, width: 43};

addPropertyControls(FrameroDm28HZtn, {DApvjrdfG: {defaultValue: "About ", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FrameroDm28HZtn, [{explicitInter: true, fonts: [{family: "Roboto", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9vBh05IsDqlA.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})